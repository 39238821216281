<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 88vh;">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="display: block;">
                <v-img :src="logo" height="110" contain></v-img>
            </div>
            <div style="display: block; text-align: center; margin-top: 30px;">
                <h2> Error 404 Not found </h2>
            </div>
        </div>
        <div style="display: block; position: fixed; bottom: 15px;">
           <small> Copyright &copy;  {{ new Date().getFullYear() }} Xera Clinic </small>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logo: require('../assets/main-logo.svg'),
        }
    }
}
</script>

<style>

</style>